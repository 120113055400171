import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { getLocalStorage, setLocalStorage } from "../utils";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { EmailSend } from "./SendEmail";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ mr: 15, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function CustomizedDialogs({
  buttonName = "",
  modalTitle = "Leave Your Details Here",
}) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(getLocalStorage("name") || "");
  const [email, setEmail] = useState(getLocalStorage("email") || "");
  const [mobileNumber, setMobileNumber] = useState(
    getLocalStorage("mobileNumber") || ""
  );
  const [time, setTime] = useState(getLocalStorage("time") || "");
  const [message, setMessage] = useState(getLocalStorage("message") || "");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    const params = {
      name: name,
      number: mobileNumber,
      email: email,
      time: time,
      message: message,
      property: "Eldeco Viviana",
      terms: true
    };
    try {
      const res = EmailSend(params);
      console.log("res", res);
      setOpen(false);
      delete localStorage.name;
      delete localStorage.mobileNumber;
      delete localStorage.time;
      delete localStorage.email;
      delete localStorage.message;
      setName("");
      setMessage("");
      setTime("");
      setEmail("");
      setMobileNumber("");
    } catch (err) {}
  };

  const checkDisabled = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return !(
      name?.length > 0 &&
      mobileNumber?.length > 8 &&
      mobileNumber?.length < 15 &&
      emailRegex.test(email)
    );
  };

  return (
    <div>
      <Button
        variant="outlined"
        className="home_button"
        onClick={handleClickOpen}
      >
        {buttonName}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className="font_bold white_space_no_wrap"
        >
          {modalTitle}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="input_boxes_wrapper">
            <div className="input_container half_width">
              <input
                placeholder="Your Name"
                value={name}
                onChange={(e) => {
                  setLocalStorage("name", e.target.value);
                  setName(e.target.value);
                }}
              />
              <input
                placeholder="Email ID"
                value={email}
                onChange={(e) => {
                  setLocalStorage("email", e.target.value);
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="input_container half_width">
              <PhoneInput
                placeholder="Phone"
                value={mobileNumber}
                defaultCountry="IN"
                displayInitialValueAsLocalNumber
                onChange={(e) => {
                  setLocalStorage("mobileNumber", e);
                  setMobileNumber(e);
                }}
              />
              <FormControl sx={{ m: 1, minWidth: 200 }} className="dropdown">
                <InputLabel id="demo-simple-select-helper-label">
                  Best Time To Call You
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  className="dropdown_inside"
                  value={time}
                  label="Best Time To Call You"
                  onChange={(e) => {
                    setLocalStorage("time", e.target.value);
                    setTime(e.target.value);
                  }}
                >
                  <MenuItem value="Within 15 Minutes">
                    Within 15 Minutes
                  </MenuItem>
                  <MenuItem value="08:00 AM To 12:00 PM">
                    08:00 AM To 12:00 PM
                  </MenuItem>
                  <MenuItem value="12:00 PM To 04:00 PM">
                    12:00 PM To 04:00 PM
                  </MenuItem>
                  <MenuItem value="04:00 PM To 08:00 PM">
                    04:00 PM To 08:00 PM
                  </MenuItem>
                  <MenuItem value="AnyTime">AnyTime</MenuItem>
                </Select>
                {/* <FormHelperText>With label + helper text</FormHelperText> */}
              </FormControl>
            </div>
            <div className="textarea_container">
              <textarea
                value={message}
                rows="4"
                placeholder="Message"
                cols="50"
                onChange={(e) => {
                  setLocalStorage("message", e.target.value);
                  setMessage(e.target.value);
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            className="home_button small_btn modal_btn"
            onClick={handleSubmit}
            disabled={checkDisabled()}
          >
            {buttonName}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}


// import { ReactComponent as GymIcon } from "./assets/amenities/dumbbell.svg";
import GymIcon from "./assets/amenities/dumbbell.svg";
import CafeIcon from "./assets/amenities/cafe.svg";
import SkateIcon from "./assets/amenities/skate.svg";
import PoolIcon from "./assets/amenities/swimming-pool.svg";
import TennisIcon from "./assets/amenities/tennis-player.svg";
import MasterPlan from "./assets/floor_plan/Master_Plan.jpg";
import FloorPlan1 from "./assets/floor_plan/floor_plan1.jpg";
import FloorPlan2 from "./assets/floor_plan/floor_plan2.jpeg";

export const MENU_ITEMS = [
  { id: 1, label: "Home", class: "homewrapper_main" },
  { id: 2, label: "About", class: "welcome_page" },
  { id: 3, label: "Highlights", class: "whychoseWarp" },
  { id: 4, label: "Amenities", class: "Amenities_warpper" },
  { id: 5, label: "Floor Plan", class: "all_plan" },
  { id: 6, label: "Price", class: "Price_warpper" },
  //{ id: 7, label: "Gallery", class: "all_plan" },
  // { id: 8, label: "Location", class: "location_wrapper" },
];

export const HOME_HEADING = "ELDECO La Vida Bella";
export const HOME_SECONDARY_HEADING = "Sector 12, Noida Ext, UP";

export const HOME_INFORMATION = [
  {
    heading: "Price:",
    value: "₹ 2.00 CR Onwards",
  },
  {
    heading: "Sizes:",
    value: "1905 SQ.FT - 3300 SQ.FT",
  },
  {
    heading: "Configurations:",
    value: "3 & 4 BHK Luxury Apts.",
  },
  {
    heading: "Status:",
    value: "New Launch",
  },
  {
    heading: "Rera No.: ",
    value: "UPRERAPRJ136219/04/2024",
  },
];

// Welcome Page

export const WELCOME_PAGE = {
  heading: "Welcome to ELDECO La Vida Bella Sector 12, Noida Ext.",
  content: [
    "Welcome to ELDECO La Vida Bella, an exclusive gated community offering a private oasis for luxurious living in the heart of Noida. Spread across 4.5 acres, this new launch project presents 3 and 4-bedroom apartments ranging from 1905 sqft to 3300 sqft, catering to those who appreciate the finer things in life.",
    "With 356 super luxury apartments in B+G+22 storied structures, this township is destined to become the world's best place to call home. Located in Sector 12, Noida Ext., it enjoys proximity to the Upcoming Gr. Noida West Sector 12 Metro Station and the 130 ft. wide which connect FNG and yamuna expressway, ensuring easy access to the bustling IT belt of Noida and a range of daily conveniences, including schools, colleges, hospitals, and shopping arcades. Experience a life of opulence and serenity at ELDECO La Vida Bella.",
    "Experience the epitome of luxury at ELDECO La Vida Bella in Sector 12, Noida Ext., while embracing an environmentally conscious lifestyle. The project's eco-friendly features include a massive rainwater harvesting capacity of approximately 15.8 million liters, ensuring you contribute to a sustainable future while indulging in opulence.",
  ],
};

// Why Choose Us Page

export const WHY_CHOOSE_US = {
  heading: "Why Choose ELDECO La Vida Bella Sector 12, Noida Ext.",
  content: [
    "ELDECO La Vida Bella in Sector 12, Noida Ext. is on its way. The development located in Gr. Noida West is spread over a vast areas and provides an appealing area for parks and various facilities for your enjoyment. The surroundings offer an ideal home location for stunning views, and blends the tranquil natural beauty of nature with fashionable sophistication for a stunning perspective. The home is built with bedrooms, hallways as well as a kitchen with a view in mind, as high-end verandahs, as well as large windows for you to enjoy. The apartments are equipped with everything you need for a relaxing lifestyle. With a variety of fashionable and stylish fixtures, you are sure that you have everything you require for an enjoyable and fulfilling life.",
  ],
  boxes: [
    {
      heading: "Infrastructure",
      content:
        "Noida is well-connected to New Delhi, Faridabad, and Ghaziabad by public transportation networks including buses and metros. But there is a problem with the Delhi Metro does not go beyond Noida City Centre. It is the Yamuna Expressway, a 165-kilometer-long expressway with",
    },
    {
      heading: "Real Estate and Construction",
      content:
        "Floor space index (FSI which is the ratio of the floor space that is constructed within a building to the plot) within Noida is significantly greater than Delhi and also higher than other regions of the NCR however the FSI differs depending on the region. It is believed that the Noida Municipal Corporation may soon provide all pertinent information about industrial, commercial and residential properties online. For residential properties Stamp duty and registration costs amount to 12.5 percentage of value of the transaction as well as 2% of amount of the transaction accsix lanes links Greater Noida and Agra. Noida Municipal Corporation Noida Municipal Corporation has decided to construct CNG facilities all over the city and with CNG being available to each sector. The authorities of Noida along with Greater Noida have decided to offer Wi-Fi at all hotels, schools as well as shopping malls along with commercial facilities. ording to. Property tax is payable via online. Tax on property for Noida depends on the area of property owned, the kind of property owned and the time that the house is. Taxes on property are paid each year.",
    },
  ],
};

// Amneties Page

export const AMNETIES_PAGE = {
  heading: "Amenities",
  content: [
    "Eldeco La Vida Bella, located in Sector 12, Greater Noida West, epitomizes contemporary living with its exceptional array of amenities. This thoughtfully designed residential project offers a luxurious clubhouse, serving as a hub for relaxation and socializing. The clubhouse features a well-appointed gymnasium with the latest fitness equipment, a dedicated space for yoga and aerobics, and a rejuvenating swimming pool that provides a perfect escape from daily stress.",
    "The project is set within beautifully landscaped gardens, creating a tranquil environment that complements its modern infrastructure. For added convenience, Eldeco La Vida Bella includes ample parking space for residents and visitors, ensuring ease of access. The property is equipped with 24/7 power backup, ensuring uninterrupted services, and advanced security systems that offer a secure living environment.",
    "Further enhancing the living experience, the project incorporates high-speed elevators for quick and efficient movement, and well-designed common areas that foster a sense of community. With these top-notch amenities, Eldeco La Vida Bella promises a blend of luxury, comfort, and security, making it an ideal choice for discerning homeowners seeking an elevated lifestyle in Greater Noida West.",
  ],
  boxes: [
    {
      heading: "Swimming pool",
      icon: PoolIcon,
      content:
        "Swimming is thought of as an exercise or leisure. Its main benefits include cooling down the body, relaxing exercising, and training for athletics. Swimming during your leisure time is a fantastic method to relax. Because the density of the human body is comparable to water's bones and joints aren't subjected to a lot of stress. To keep your body and mind in health, ELDECO La Vida Bella Sector 12, Noida Ext. offers integrated swimming pools.",
    },
    {
      heading: "GYM",
      icon: GymIcon,
      // icon: <GymIcon />,
      content:
        "Everybody these days is obsessed with being fit and healthy. They eat, exercise and perform a range of other activities to reach their goals. Gyms are popular for many reasons. We all want to build muscle to build fitness. Others may seek the more natural way to boost their energy that caffeine. With this in mind, ELDECO La Vida Bella Sector 12, Noida Ext. has a gym that will keep you in a good physical and mental health.",
    },
    {
      heading: "Spa",
      icon: CafeIcon,
      content:
        "In addition to having easy and quick access to the fitness center and swimming pool, ELDECO La Vida Bella Sector 12, Noida Ext. offers spa facilities that create a place that allows you to relax your mind and body. Spa therapy is a way to promote physical, mental and spiritual healing that is crucial to a healthy lifestyle and living a long , healthy life.",
    },
    {
      heading: "Kids Play Area",
      icon: TennisIcon,
      content:
        "Children learn to balance, move and lift objects while playing. This helps them develop basic movement skills that can help them remain active throughout their lives. This is why ELDECO La Vida Bella Sector 12, Noida Ext. gives children easy and quick access to playgrounds.",
    },
    {
      heading: "Walking Path",
      icon: SkateIcon,
      content:
        "The Walking Path facility has been integrated at ELDECO La Vida Bella Sector 12, Noida Ext. to those people who usually love to do morning walk and as well as evening walk. Walking is also the main secret for the fitness.",
    },
  ],
};

// Plan Page

export const PLAN_PAGE = {
  master: {
    heading: "Master Plan",
    image: MasterPlan,
  },
  floor: {
    heading: "Floor Plan",
    image: [FloorPlan1, FloorPlan2],
  },
};

// Price Page

export const PRICE_PAGE = {
  heading: "Price",
  tableHeader: [
    { key: "unit", value: "Unit Type" },
    { key: "size", value: "SIZE (Carpet) Sq.ft	" },
    { key: "price", value: "PRICE (in ₹)	" },
    { key: "action", value: "" },
  ],
  tableData: [
    {
      unit: "3 BHK",
      size: "1905 SQ.FT.",
      price: "₹ 2.00 CR",
    },
    {
      unit: "4 BHK",
      size: "2755 SQ.FT.",
      price: "₹ 3.00 CR",
    },
    {
      unit: "4 BHK + Servent ",
      size: "3300 SQ.FT.",
      price: "₹ 3.79 CR",
    },
  ],
};

export const FAQ_LIST = [
  {
    question: "Where is the location of Eldeco la vida bella in Gr. Noida?",
    answer:
      "The project is located in Sector 12, Greater Noida, Gautam Buddha Nagar, Utter Pradesh - 201318.",
  },
  {
    question:
      "Does the project hold the potential for value appreciation in the future?",
    answer:
      "Of course, Yes. The direct connectivity of project to upcoming Metro Station in Sector 12 Greater Noida provides very high appreciation potential. Along with luxury amenities with in the project.",
  },
  {
    question:
      "How can I schedule a site visit to ELDECO la vida bella, Gr. Noida, Utter Pradesh?",
    answer:
      "Click on Schedule Appointment Button, Our Sales Manager will contact you to confirm Site Visit timings.",
  },
  {
    question: "What is the RERA No of Eldeco la vida bella, Gr. Noida West?",
    answer: "Rera No. :UPRERAPRJ136219 .",
  },
];
